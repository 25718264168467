import BaseHandler from "./BaseHandler";
import CoreHandler from "./CoreHandler";
import { DEFAULT_CANVAS_JSON } from "@utils/constant";
import { fabric } from "@utils/fabric";

export type TransactionType =
  | "add"
  | "remove"
  | "reorder"
  | "redo"
  | "undo"
  | "modified";

export interface TransactionEvent {
  json: string;
  type: TransactionType;
}

class TransactionHandler extends BaseHandler {
  public coreHandler: CoreHandler;

  public processing: boolean;

  public redos: TransactionEvent[];

  public undos: TransactionEvent[];

  public state: Record<string, unknown>;

  constructor(coreHandler: CoreHandler) {
    super();

    this.coreHandler = coreHandler;
    this.processing = false;
    this.redos = [];
    this.undos = [];
    this.state = JSON.parse(DEFAULT_CANVAS_JSON);

    this.onDesignLoaded = this.onDesignLoaded.bind(this);
  }

  onDesignLoaded(): void {
    // @ts-ignore - extended by ourselves in Editor
    this.state = this.coreHandler.canvas._toJSON() as unknown as Record<
      string,
      unknown
    >;
  }

  initialize(): void {
    if (!this.coreHandler.isDesignLoaded) {
      this.coreHandler.eventHandler.on("design:loaded", this.onDesignLoaded);
    } else {
      this.onDesignLoaded();
    }
  }

  destroy(): void {}

  undo(): void {
    const undo = this.undos.pop();
    if (!undo) {
      return;
    }
    console.log("undo");
    this.redos.push({
      type: "redo",
      json: JSON.stringify(this.state),
    });
    this.replay(undo);
  }

  redo(): void {
    const redo = this.redos.pop();
    if (!redo) {
      return;
    }
    console.log("redo");
    this.undos.push({
      type: "undo",
      json: JSON.stringify(this.state),
    });
    this.replay(redo);
  }

  save(type: TransactionType): void {
    if (this.processing) {
      return;
    }

    try {
      const json = JSON.stringify(this.state);
      this.redos = [];
      this.undos.push({
        type,
        json,
      });

      // @ts-ignore - extended by ourselves in Editor
      this.state = this.coreHandler.canvas._toJSON() as any;

      // triggers when redos / undos are changed
      this.emit("change");
    } catch (error) {
      console.log(error);
    }
  }

  replay(transaction: TransactionEvent): void {
    this.state = JSON.parse(transaction.json);
    const { objects } = this.state as {
      objects: Record<string, unknown>[];
    };

    fabric.util.enlivenObjects(objects, (enlivenedObjects) => {
      this.coreHandler.canvas.renderOnAddRemove = false;

      // non-workspace will be removed
      this.coreHandler.canvas.remove(...this.coreHandler.canvas.getObjects());
      this.coreHandler.canvas.add(...enlivenedObjects);

      this.coreHandler.canvas.renderAll();

      // triggers when redos / undos are changed
      this.emit("change");
    });
  }
}

export default TransactionHandler;
